
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































.solution-card {
  ::v-deep {
    .solution-card__btn {
      background-color: $c-white;
    }
  }

  @include mq($until: l) {
    ::v-deep {
      .solution-card__btn {
        padding: 1.8rem;
        line-height: 1;
      }

      .action__icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}

.solution-card-inner {
  position: relative;
  overflow: hidden;
  border-radius: 2.4rem;

  @include mq(m) {
    padding-bottom: 20rem;
  }
}

.solution-card__content {
  @include fluid(
    padding,
    (
      s: 30px,
      xl: 48px,
    )
  );

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: $c-black-20;
  border-radius: 2.4rem;
  // transform: translateY(-40px);
  transition: all 0.8s $custom-animation;

  @include mq(m) {
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 26rem;
    // transform: translateY(0);
  }
}

.solution-card__title {
  @include fluid(
    font-size,
    (
      s: 20px,
      xl: 24px,
    )
  );

  @include mq(m) {
    margin-bottom: auto;
    // min-height: 7rem;
  }
}

.solution-card__description {
  overflow: hidden;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.8s $custom-animation;

  div {
    @include line-clamp();
  }

  .active & {
    max-height: 10rem;
    opacity: 1;
  }

  @include mq(m) {
    max-height: 0;
    opacity: 0;
  }
}

.solution-card__picture-outer {
  @include aspect-ratio(33, 27);

  @include mq(m) {
    @include aspect-ratio(38, 30);
  }
}

.solution-card__picture {
  @include get-all-space;

  z-index: -1;
  overflow: hidden;
  background-color: $c-black-60;
  transition: all 0.8s $custom-animation;

  img {
    @include get-all-space;

    object-fit: cover;
    transform: scale(1.2);
    transition: all 0.5s $custom-animation;
  }

  .solution-card.active & {
    img {
      transform: scale(1);
    }
  }
}

.solution-card__btn-wrapper {
  display: inline-flex;
  margin-top: 2rem;
}
