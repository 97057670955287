
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















.cards-grid {
  @include fluid(
    margin-bottom,
    (
      s: 40px,
      xl: 58px,
    )
  );
  position: relative;
  margin-top: 2rem;

  ::v-deep {
    [class*='action--'] {
      @include mq(m) {
        background-color: $c-white;
      }
    }
  }
}

.cards-grid-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    margin-bottom: col(1, 24);
  }

  @include mq(s) {
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      width: calc(50% - col(1, 24));
      margin-inline: col(0.5, 24);
    }
  }

  @include mq(l) {
    > div {
      width: calc((100% / 3) - col(1, 24));
    }
  }

  // display: grid;
  // grid-template-columns: repeat(1, 1fr);
  // grid-gap: col(1, 24);
  // grid-column-gap: col(1, 24);

  // @include mq(s) {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @include mq(l) {
  //   grid-template-columns: repeat(3, 1fr);
  // }
}
