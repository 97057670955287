/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * naming: if no idea, check http://chir.ag/projects/name-that-color/
 * order: from dark to light
 */

// Global colors
$c-black: #202020;
$c-black-80: #555;
$c-black-60: #959595;
$c-black-50: #cacaca;
$c-black-40: #ececec;
$c-black-20: #f8f8f8;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-red: #f00;
$c-green: #1ab700;
$c-lemon: #f8e71c;

// Project colors
$c-yellow: #f8b334;
$c-light-yellow: #ffeaba;
$c-button: #f5f5f5;
$c-excerpt: #5c5c5c;
$c-content: #484848;
$c-blue: #28577a;
$c-light-blue: #97cfff;
$c-dark-blue: #00284a;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-body: $c-black;

/*
* Dimensions
*/
$content-width-max: mq-get-breakpoint-width(wrapper);
$header-height: 60px;
$header-breakpoint: xl;

/*
* Typography
*/

$ff-default: 'Manrope', sans-serif;
$ff-alt: 'Roboto Mono', monospace;

$fs-base: 10px !default;
$fs-body: 1.4rem;
$fs-body-m: 1.6rem;

/**
* RWD
*/
$spacing: 2rem;
$mq-responsive: true;
$mq-static-breakpoint: 'large';
$ease-softer: cubic-bezier(0.53, 0.01, 0.34, 1);

/*
* Animation
*/

$custom-animation: cubic-bezier(0.53, 0.01, 0.34, 1);
